import React from 'react';
import { ReactTyped } from 'react-typed';
import { motion } from 'motion/react';

const Hero = () => {
  return (
    <div className="font-Inter text-[#202020] select-none bg-gradient-to-br from-[#F9F6FF] to-[#EAE2FF]">
      <div className="max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center px-4">
        {/* Section Highlight */}
        {/* <p
          className="text-[#FF9BCF] font-bold p-2 tracking-widest text-sm md:text-base uppercase"
          aria-label="Section heading"
        >
          A NEW ERA OF AIMER
        </p> */}

        {/* Title */}
        <h1
          className="md:text-8xl sm:text-6xl text-5xl font-extrabold md:py-6 tracking-tight"
          aria-label="Website title"
        >
          COSYAIM
        </h1>

        {/* Animated Text */}
        <div className="flex justify-center items-center font-medium">
          <p className="md:text-4xl sm:text-3xl text-xl">Competing in&nbsp;</p>
          <ReactTyped
            className="md:text-4xl sm:text-3xl text-xl font-bold animated-gradient-text"
            strings={['VALORANT', 'CS2', 'THE FINALS']}
            typeSpeed={70}
            backSpeed={50}
            loop
            aria-label="Animated text"
          />
        </div>

        {/* Button with Discord Link */}
        <a
          href="https://discord.gg/ScQE38x4rj"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Join our Discord server"
        >
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            transition={{ type: 'spring', stiffness: 300, damping: 12 }}
            className="inline-flex w-[200px] h-12 animate-shimmer items-center justify-center rounded-md bg-[linear-gradient(110deg,#FF9BCF,45%,#FFB8D6,55%,#FF9BCF)] bg-[length:200%_100%] font-medium mt-8 mx-auto py-3 text-[#202020] shadow-lg transition-colors duration-300 hover:bg-[#FFB8D6]"
          >
            Join Now
          </motion.button>
        </a>
      </div>
    </div>
  );
};

export default Hero;
