import React from 'react';
import { motion } from 'motion/react';
import { FaXTwitter, FaYoutube } from 'react-icons/fa6'; // Import the YouTube icon
import PyvnoPfp from '../assets/pyvno_pfp.jpg';

const Member = () => {
  return (
    <div className="font-Inter w-full py-[10rem] px-4 bg-gradient-to-br from-[#F9F6FF] to-[#EAE2FF] select-none">
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ type: 'spring', stiffness: 300, damping: 20, delay: 0.2 }}
        viewport={{ once: true }}
        className="max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8"
      >
        {/* Card 1 */}
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          whileInView={{ opacity: 1, scale: 1 }}
          viewport={{ once: true, amount: 0.8 }}
          className="w-full shadow-xl flex flex-col p-6 my-4 rounded-lg relative"
        >
          {/* Animated Gradient Line */}
          <div className="animated-gradient-background w-full h-[4px] absolute top-0 left-0 rounded-t-lg"></div>
          <img
            className="w-20 h-20 mx-auto mt-[-3rem] z-10 rounded-full object-cover border-2 border-white"
            src={PyvnoPfp}
            alt="/"
          />
          <h2 className="text-4xl font-bold text-center py-8 z-10">pyvno</h2>
          <p className="text-[#FF9BCF] text-center text-l font-bold z-10">
            MANAGER
          </p>
          <div className="text-center text-medium z-10">
            <p className="py-2 border-b border-gray-300 mx-8 mt-8 select-text">
              0.2 @ 1600
            </p>
            <p className="py-2 border-b border-gray-300 mx-8">1920 x 1080</p>
            <p className="py-2 border-b border-gray-300 mx-8">Immortal 3</p>
          </div>
          {/* Social Media Icons */}
          <div className="flex justify-center space-x-6 mt-6 z-10">
            <motion.a
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              transition={{ type: 'spring', stiffness: 300, damping: 10 }}
              href="."
              target="_blank"
              rel="noopener noreferrer"
              className="text-[#202020]"
              aria-label="Twitter"
            >
              <FaXTwitter size={25} />
            </motion.a>
            <motion.a
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              transition={{ type: 'spring', stiffness: 300, damping: 10 }}
              href="."
              target="_blank"
              rel="noopener noreferrer"
              className="text-[#202020]"
              aria-label="YouTube"
            >
              <FaYoutube size={25} />
            </motion.a>
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Member;
