import React from 'react';
import { motion } from 'framer-motion';
import LogoDark from '../assets/logo_dark.png';

const About = () => {
  return (
    <div className="font-Inter w-full h-[700px] bg-gradient-to-bl from-[#EAE2FF] to-[#F9F6FF] py-16 px-6 select-none flex justify-center">
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ type: 'spring', stiffness: 300, damping: 20, delay: 0.2 }}
        viewport={{ once: true }}
        className="max-w-[1240px] mx-auto grid md:grid-cols-2 items-center gap-12"
      >
        {/* Logo Section */}
        <motion.img
          whileTap={{ scale: 0.9 }}
          transition={{ type: 'spring', stiffness: 300, damping: 10 }}
          initial={{ opacity: 0, scale: 0.8 }}
          whileInView={{ opacity: 1, scale: 1 }}
          viewport={{ once: true, amount: 0.8 }}
          className="w-3/4 sm:w-2/3 md:w-[300px] mx-auto my-4"
          src={LogoDark}
          alt="COSYAIM Logo"
        />
        {/* Text Section */}
        <div className="flex flex-col justify-center ">
          <motion.p
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{
              type: 'spring',
              stiffness: 200,
              damping: 20,
              delay: 0.4,
            }}
            viewport={{ once: true, amount: 0.8 }}
            className="animated-gradient-text font-semibold tracking-wide text-sm uppercase mb-2"
          >
            Aim with Heart
          </motion.p>
          <motion.h1
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{
              type: 'spring',
              stiffness: 200,
              damping: 20,
              delay: 0.5,
            }}
            viewport={{ once: true, amount: 0.8 }}
            className="md:text-5xl sm:text-4xl text-3xl font-bold text-[#202020] leading-tight"
          >
            COSYAIM
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{
              type: 'spring',
              stiffness: 200,
              damping: 20,
              delay: 0.6,
            }}
            viewport={{ once: true, amount: 0.8 }}
            className="text-gray-600 mt-4 leading-relaxed"
          >
            Established in 2024, is a dynamic organization with a mission to
            grow its influence and provide a platform for uncovering and
            promoting hidden talents. We strive to connect talented individuals
            with opportunities, creating a vibrant community where skills and
            potential can flourish. Through collaboration and innovation, the
            group aims to leave a lasting impact by celebrating and amplifying
            the voices of those who deserve to be seen and heard.
          </motion.p>
        </div>
      </motion.div>
    </div>
  );
};

export default About;
