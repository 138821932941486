import React, { useState } from 'react';
import { motion } from 'motion/react';
import { FaUser } from 'react-icons/fa';

const Navbar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="flex justify-between items-center h-24 max-w-[1700px] mx-auto px-4 text-[#202020] select-none">
      {/* Logo */}
      <h1
        className="text-4xl font-extrabold tracking-wide cursor-pointer bg-gradient-to-r from-[#FF9BCF] via-[#FFB8D6] to-[#FF9BCF] bg-[length:200%_100%] animate-shimmer text-transparent bg-clip-text"
        aria-label="Cosyaim Logo"
      >
        COSYAIM.
      </h1>

      {/* Profile Section */}
      <div className="flex items-center space-x-4">
        <motion.div
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          transition={{ type: 'spring', stiffness: 300, damping: 12 }}
          onClick={handleModalToggle}
          className="cursor-pointer"
        >
          <FaUser className="text-2xl" />
        </motion.div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 backdrop-blur-md flex justify-center items-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-8 w-96 text-center relative">
            <button
              onClick={handleModalToggle}
              className="absolute top-4 right-4 text-[#202020] hover:text-red-500 text-2xl font-bold"
            >
              &times;
            </button>
            <h2 className="text-2xl font-bold mb-4">LOGIN</h2>
            <p className="text-gray-600 mb-6">
              login with your credentials given by{' '}
              <span className="text-[#FF9BCF]">cosyaim</span>
            </p>
            <form className="space-y-4">
              <div>
                <input
                  type="text"
                  className="w-full px-3 py-2 border rounded-md focus:ring-[#FF9BCF] focus:border-[#FF9BCF] cursor-pointer"
                  placeholder="Username"
                />
              </div>
              <div>
                <input
                  type="password"
                  className="w-full px-3 py-2 border rounded-md focus:ring-[#FF9BCF] focus:border-[#FF9BCF] cursor-pointer"
                  placeholder="Password"
                />
              </div>
              <button
                type="submit"
                className="w-full py-2 px-4 bg-[#FF9BCF] text-white font-medium rounded-md shadow-md hover:bg-[#ff7db5] transition"
              >
                Login
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
